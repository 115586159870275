<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      庫存管理
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="店點"
          v-model="branch"
          :items="branches"
          hide-details
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3" md="2">
        <v-select
          label="商品種類"
          v-model="productCategory"
          :items="productCategoryOptions"
          hide-details
          outlined
          dense
          clearable
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          v-model="showOnlyInStock"
          label="只顯示有庫存商品"
        ></v-checkbox>
      </v-col>

      <v-col
        v-if="allowExportRoles.includes(this.$store.state.user.角色)"
        cols="auto"
        class="d-none d-sm-block pa-0 ml-auto"
      >
        <v-btn
          color="primary"
          class=""
          @click="exportToExcel(printProduct, branch)"
          :disabled="filterProducts.length === 0"
          >匯出</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="nearExpiredStock.length !== 0">
      <v-col>
        <v-alert type="error" outlined>
          即將到期商品：{{ nearExpiredStock.join("、") }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :items="filterProducts"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [20, 50, 100],
            showCurrentPage: true,
            showFirstLastPage: true,
          }"
        >
          <template v-slot:header.inventory> 當月盤點 </template>
          <template v-slot:item.inventory="{ item }">
            <div v-if="inventoryMap[item._id] || inventoryMap[item.productId]">
              {{
                inventoryMap[item._id]?.amount ||
                inventoryMap[item.productId]?.amount
              }}
              <v-icon
                @click="openDialog(item, 'inventory')"
                x-small
                class="mr-2"
              >
                mdi-pen
              </v-icon>
            </div>
            <v-btn
              v-else
              class="ma-1"
              small
              color="secondary"
              @click="checkStock(item)"
            >
              +盤點
            </v-btn>
          </template>
          <template v-slot:item.expired="{ item, index }">
            <span
              :class="{
                'grey--text': !item.expired,
                'font-weight-medium': item.expired,
                'red--text': isNearExpired(item.expired),
                'text-right': true,
              }"
            >
              {{ item.expired || "——————" }}
            </span>
            <v-menu
              :value="activeMenuIndex === index"
              :close-on-content-click="false"
              persistent
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="open(item, index)"
                  x-small
                  class="mr-2"
                >
                  mdi-pen
                </v-icon>
              </template>
              <v-date-picker
                v-model="editedItem.expired"
                no-title
                scrollable
                locale="zh-tw"
              >
                <v-btn text color="primary" @click="editedItem.expired = ''">
                  清除
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="close"> 取消 </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="update(editedItem, 'expired')"
                >
                  儲存
                </v-btn>
              </v-date-picker>
            </v-menu>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              small
              color="error"
              class="ma-1"
              @click="openDialog(item, 'scrapped')"
            >
              報廢
            </v-btn>
            <v-btn
              class="ma-1"
              small
              color="warning"
              @click="openDialog(item, 'regulate')"
            >
              校正
            </v-btn>
            <v-btn
              class="ma-1"
              small
              color="success"
              @click="openDialog(item, 'shipped')"
            >
              出貨
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogStockScrapped
      v-if="dialog.scrapped"
      v-model="dialog.scrapped"
      :stock="selectedStock"
      :branch="branch"
      @load="load"
    ></DialogStockScrapped>
    <DialogStockRegulate
      v-if="dialog.regulate"
      v-model="dialog.regulate"
      :stock="selectedStock"
      :branch="branch"
      @load="load"
    ></DialogStockRegulate>
    <DialogStockShipped
      v-if="dialog.shipped"
      v-model="dialog.shipped"
      :stock="selectedStock"
      :branch="branch"
      @load="load"
    ></DialogStockShipped>
    <DialogStockInventory
      v-if="dialog.inventory"
      v-model="dialog.inventory"
      :stock="selectedStock"
      :branch="branch"
      @load="load"
      :inventory="inventoryMap[selectedStock?._id]"
    ></DialogStockInventory>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import util from "@/mixins/util";
import stock from "@/mixins/stock";
import _ from "lodash";
import DialogStockScrapped from "@/components/dashboard/stock/DialogStockScrapped.vue";
import DialogStockRegulate from "@/components/dashboard/stock/DialogStockRegulate.vue";
import DialogStockShipped from "@/components/dashboard/stock/DialogStockShipped.vue";
import DialogStockInventory from "@/components/dashboard/stock/DialogStockInventory.vue";

export default {
  name: "Stock",
  mixins: [util, stock],
  components: {
    DialogStockScrapped,
    DialogStockRegulate,
    DialogStockShipped,
    DialogStockInventory,
  },
  data: () => ({
    dayjs,
    dialog: {
      scrapped: false,
      regulate: false,
      shipped: false,
      inventory: false,
    },
    activeMenuIndex: null,
    editedItem: {
      expired: "",
    },
    selectedStock: null,
    branch: "",
    productCategory: null,
    productCategoryOptions: [],
    stocks: [],
    products: [],
    inventory: [],
    inventoryMap: {},
    data: [],
    keyword: "",
    showOnlyInStock: true,
    headers: [
      { text: "商品名稱", value: "name" },
      { text: "庫存量", value: "amount", align: "center" },
      { text: "有效期限", value: "expired", align: "center" },
      { text: "操作", value: "action", align: "center", sortable: false },
      { text: "最新盤點", value: "inventory", align: "left", sortable: false },
      {
        text: "校正時間",
        value: "regulatedAt",
        align: "left",
        // class: "d-none d-sm-table-cell",
        // cellClass: "d-none d-sm-table-cell",
      },
      ,
    ],
    allowExportRoles: ["管理者", "主管"],
    branches: [
      { text: "文賢店", value: "文賢" },
      { text: "崇善店", value: "崇善" },
      { text: "奇美店", value: "奇美" },
      { text: "總倉", value: "總倉" },
    ],
  }),
  computed: {
    _: () => _,
    filterProducts() {
      let products = this.stocks;
      if (this.keyword) {
        products = _.filter(products, (product) =>
          product.name.toUpperCase().includes(this.keyword.toUpperCase())
        );
      }
      if (this.showOnlyInStock) {
        products = _.filter(products, (product) => product.amount > 0);
      }
      if (this.productCategory) {
        products = _.filter(
          products,
          (product) => product.category === this.productCategory
        );
      }
      return products;
    },
    printProduct() {
      let products = this.filterProducts;
      return products
        .map((product) => _.omit(product, this.ignoreKeys))
        .map((product) => {
          const cost =
            _.isNumber(product.price) && _.isNumber(product.amount)
              ? product.price * product.amount
              : null;
          return {
            ...product,
            cost,
            price: product.price,
            amount: product.amount,
          };
        })
        .map((product) => {
          return Object.fromEntries(
            Object.entries(product).map(([key, value]) => [
              this.translatedHeader[key] || key,
              value,
            ])
          );
        });
    },
    nearExpiredStock() {
      return this.filterProducts
        .filter(
          (stock) => this.isNearExpired(stock.expired) && stock.amount > 0
        )
        .map((product) => product.name);
    },
  },
  async created() {
    await this.getProducts();
  },
  methods: {
    openDialog(stock, type) {
      this.selectedStock = stock;
      this.dialog[type] = true;
    },
    async load() {
      if (!this.branch) return;

      this.$vloading.show();

      const { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.branch,
        },
      });
      let stocks = _.mapKeys(data, "productId");
      this.stocks = this.products.map((product) => ({
        amount: 0,
        productId: product._id,
        ...product,
        _id: null,
        ...stocks[product._id],
      }));

      await this.getInventory();

      this.$vloading.hide();
    },
    async getProducts() {
      const { data } = await this.axios.get(`/product`);
      this.products = data.filter((o) => o.name);
      this.productCategoryOptions = Object.keys(
        _.groupBy(this.products, "category")
      ).filter((o) => !!o);
    },
    async getInventory() {
      const { data } = await this.axios.get(`/inventory/${this.branch}`);
      this.inventory = data;
      this.inventoryMap = _.mapKeys(data, "stock._id");
    },
    async updateExpired(item) {
      this.$vloading.show();
      try {
        await this.axios.put(`/stock/${item._id}/update-expired`, {
          expired: item.expired,
        });
        this.$toast.success("更新完成！");
        await this.load();
      } catch (error) {
        this.$toast.error("更新失敗！");
      }
      this.close();
      this.$vloading.hide();
    },
    close() {
      this.activeMenuIndex = null;
      this.editedItem = {};
    },
    open(item, index) {
      this.activeMenuIndex = index;
      this.editedItem = _.cloneDeep(item);
    },
    async checkStock(stock) {
      if (!stock._id) {
        await this.initStock(stock);

        const initStock = this.filterProducts.find(
          (product) => product.productId === stock.productId
        );
        this.openDialog(initStock, "inventory");
        return;
      }

      this.openDialog(stock, "inventory");
    },
    async initStock(stock) {
      this.$vloading.show();
      await this.axios.post(`/transaction`, {
        type: "校正",
        from: this.branch,
        productId: stock.productId,
        amount: 0,
        comment: "建立初始庫存",
      });
      await this.load();
      this.$vloading.hide();
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss"></style>
